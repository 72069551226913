import React, { Fragment } from "react"
import { Picture } from 'react-responsive-picture';

import GetBodyItem from "../components/_helpers/_get_body_item";

import Layout from '../components/layout';
import Head from '../components/head'
import { ShareBlog } from '../components/_share/_share_blog';
import { TitleStroke } from "../components/_titles/_header_title_stroke";
import { GenericHeader } from "../components/_generic/_generic_header";

import '../styles/pages/generic/_generic.scss'

const GenericPage = (props) => {

    const _item = props.pageContext.item;
    const color_start = '#ff4056';
    const color_end = '#ff4056';

    const metasData = new GetBodyItem().get_item(_item.node, "PrismicGenericBodyMetas");
    const metas = {
        title: 'Cinsa',
        description: 'Cinsa ',
        image: ''
    }
    if (metasData) {
        metas.title = metasData.primary.title.text
        metas.description = metasData.primary.description.text
        metas.image = metasData.primary.image_share.url
    }

    function get_detail() {
        return (
            <div className="genericpage_container">
                <div className="genericpage_content_top genericpage_content" dangerouslySetInnerHTML={{ __html: _item.node.data.content.html }}></div>
                {
                    _item.node.data.body &&
                    _item.node.data.body.map((_block, key) => {
                        switch (_block.__typename) {
                            case "PrismicGenericBodyCopy":
                                return (
                                    <Fragment key={`genericpage_copy_${key}`}>
                                        {
                                            _block.primary &&
                                            _block.primary.title1 && _block.primary.title_filled1 &&
                                            _block.primary.title1.text !== '' && _block.primary.title_filled1.text !== '' &&
                                            <TitleStroke
                                                useH1={false}
                                                textStroke={_block.primary.title1.text}
                                                text={_block.primary.title_filled1.text}
                                                colorStroke="#3677D2"
                                                colorText="#3F5CD6"
                                            />
                                        }
                                    </Fragment>
                                )
                                break;
                            case "PrismicGenericBodyTexto":
                                return (
                                    <Fragment key={`genericpage_text_${key}`}>
                                        {
                                            _block.items[0].description.text !== '' &&
                                            <div key={`genericpage_text_${key}`} className="genericpage_content_bottom genericpage_content" dangerouslySetInnerHTML={{ __html: _block.items[0].description.html }} />
                                        }
                                    </Fragment>
                                )
                                break;
                        }
                    })
                }
            </div>
        );
    }

    return (
        <Layout>
            <div className="genericpage-container">
                <GenericHeader data={_item} useH1={true} colorStroke="#3F5CD6" colorText="#375ADE" _className="genericpage-header" />
                <div className="genericpage_header_stage">
                    <div className="genericpage_carousel">
                        <Picture sources={[
                            {
                                srcSet: _item.node.data.main_image_xs.url,
                                media: "(max-width: 767px)",
                            },
                            {
                                srcSet: _item.node.data.main_image_sm.url,
                                media: "(min-width: 768px) and (max-width: 1359px)",
                            },
                            {
                                srcSet: _item.node.data.main_image_lg.url,
                                media: "(min-width: 1360px) and (max-width: 1919px)",
                            },
                            {
                                srcSet: _item.node.data.main_image_xlg.url,
                                media: "(min-width: 1920px)",
                            }

                        ]} />
                    </div>
                </div>
                <div className="genericpage_body">
                    <div className='genericpage_body_main'>
                        <div className="genericpage_share">
                            <ShareBlog _fb_to={`/${_item.node.uid}`} _tw_to={`/${_item.node.uid}`} _color_start={color_start} _color_end={color_end} _section='genericpage' />
                        </div>
                        {get_detail()}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default GenericPage
