import React from 'react'

import { TitleStroke } from '../_titles/_header_title_stroke'

import styles from '../../styles/_components/_generic/_generic_header.module.scss';

export const GenericHeader = ({ _className, data, useH1 = false, colorStroke = "#F62837", colorText = "#375ADE" }) => {
    // console.log(data);
    return (
        <div className={`${_className} ${styles.oGenericHeader}`}>
            <div className={`${styles.oGenericHeaderStage}`}>
                <div className={styles.oGenericHeaderInfo__container}>
                    <div className={`${styles.oGenericHeaderBlogtype}`} dangerouslySetInnerHTML={{ __html: data.node.data.title_small_styled.html }} />
                    <TitleStroke
                        useH1={useH1}
                        textStroke={data.node.data.title.text}
                        text={data.node.data.title_filled.text}
                        colorStroke={colorStroke}
                        colorText={colorText}
                        _className={`${styles.oGenericHeaderTitle}`}
                    />
                </div>
            </div>
        </div>
    )
}